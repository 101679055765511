.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.copy-code-button {
  position: relative;
  font-size: 20px;
  background-color: #ffffff44;
  border-radius: 16px;
  padding: 2px 5px;
  cursor: copy;
  transition: all 400ms;
  width: auto;
  height: 35px;
  margin-bottom: 50px;
}

.copy-code-button:hover {
  background-color: #ffffff66;
}

.copy-code-button:hover::after {
  position: absolute;
  font-size: 20px;
  background-color: #ffffff44;
  border-radius: 16px;
  height: 35px;
  padding: 3px 12px;
  top: 0;
  content: "copy";
  margin-left: 20px;
}

.copy-code-button:active {
  background-color: #ffffffcc;
}

.copy-code-button:active::after {
  position: absolute;
  font-size: 20px;
  background-color: #ffffff44;
  border-radius: 16px;
  height: 35px;
  padding: 3px 12px;
  top: 0;
  content: "copied";
  margin-left: 20px;
}

