html {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
  min-height: 100vh;

  background-color: rgb(226, 154, 42);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  min-height: 100vh;
}

.app-container {
  background-color: #e29a2a;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.poster-and-timer {
  width: 100%;
  height: 30vh;
  position: relative;
  box-sizing: border-box;
}

.survey {
  background-color: white;
  display: flex;
  height: 70vh;
  width: 100%;
  position: relative;
}

.session-timer {
  position: absolute;
  top: 0;
  left: 0;
}

/* desktop */
@media only screen and (min-width: 700px) {
  .app-container {
    flex-direction: row-reverse;
  }

  .poster-and-timer {
    width: 70%;
    height: 100vh;
    border-left: 1px solid white;
  }

  .session-timer {
    width: 80px;
    height: 80px;
  }

  .session-timer-text {
    display: none;
  }

  .survey {
    width: 30%;
    height: 100vh;
  }
}

/* mobile landscape */
@media only screen and (max-height: 500px) {
  .session-timer {
    transform: scale(0.5) translate(50%, -50%);
  }

  .session-timer-text {
    display: none;
  }
}

/* mobile portrait */
@media only screen and (max-width: 700px) {
  .app-container {
    flex-direction: column;
  }

  .poster-and-timer {
    border-bottom: 1px solid white;
  }

  .session-timer {
    width: 60px;
  }

  .session-timer-text {
    display: none;
  }
}
